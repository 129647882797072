import DraggableSlider from '@/components/organisms/draggable-slide';
import styles from './styles/skeleton-loader.module.scss';
import orderStyle from './styles/order-details.module.scss';
import Card from '@/components/molecules/card';
import Img from '@/components/molecules/img';

export const SkeletonLine = ({ width, height, margin, customStyles }: any) => {
    return (
        <div
            className={styles.line_skeleton}
            style={{ width: width ? width : '100%', height: height ? height : '2em', margin: margin ? margin : '0', ...customStyles }}
        ></div>
    );
};

export const SkeletonAvatar = ({ width, height, radius, margin }: any) => {
    return (
        <div
            className={styles.avatar_skeleton}
            style={{
                width: width ? width : '100px',
                height: height ? height : '100px',
                borderRadius: radius ? radius : '6px',
                margin: margin ? margin : '0'
            }}
        ></div>
    );
};

export const SkeletonHeader = () => {
    return (
        <div className={`${styles.header_skeleton} container`}>
            <div className={styles.top}>
                <SkeletonAvatar width={'120px'} height={'55px'} margin={'0 0 10px 0'} />
                <SkeletonAvatar width={'50px'} height={'40px'} margin={'0 0 10px 0'} />
            </div>
            <SkeletonLine />
            {/* <DraggableSlider>
                <div className={styles.items_list}>
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                </div>
            </DraggableSlider> */}
        </div>
    );
};

export const SkeletonSinglePageTop = () => {
    return (
        <div className={`container`}>
            <SkeletonLine height={'55px'} margin={'0 0 10px 0'} />
            <SkeletonLine height={'300px'} margin={'0 0 10px 0'} />
            <div className={styles.items_list}>
                <SkeletonLine height={'55px'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'55px'} margin={'0 0 10px 5px'} />
            </div>
            <div>
                <SkeletonLine height={'15px'} width={'85%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'15px'} width={'85%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'15px'} width={'25%'} margin={'0 5px 0 0'} />
            </div>
            <DraggableSlider>
                <div className={styles.items_list}>
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                    <SkeletonAvatar width={'50px'} height={'60px'} margin={'0 10px 0 0'} />
                </div>
            </DraggableSlider>
        </div>
    );
};

export const SkeletonCartPage = () => {
    return (
        <div className={`container`}>
            <div className={styles.items_list}>
                <SkeletonLine height={'40px'} width={'50%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'40px'} width={'50%'} margin={'0 0 10px 0'} />
            </div>
            <Card customShadow border>
                {[...Array(10)].map((_, i) => (
                    <SkeletonProductList key={i} />
                ))}
            </Card>
        </div>
    );
};

export const SkeletonCheckoutPage = () => {
    return (
        <div className={`container`}>
            <div className={styles.items_list}>
                <SkeletonLine height={'40px'} width={'100%'} margin={'0 5px 10px 0'} />
            </div>
            <div>
                <Card customShadow border>
                    <SkeletonLine height={'25px'} width={'45%'} margin={'0 0 10px 0'} />
                    <div className={styles.items_list}>
                        <SkeletonLine height={'20px'} width={'20%'} margin={'0 10px 10px 0'} />
                        <div className={`${styles.items_col} ${styles.flex_start}`} style={{ width: '100%' }}>
                            <SkeletonLine height={'10px'} width={'70%'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'10px 0 0 0'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'10px 0 0 0'} />
                        </div>
                    </div>
                    <SkeletonLine height={'140px'} width={'100%'} margin={'10px 0 0 0'} />
                </Card>
            </div>
            <div className={styles.items_list}>
                <SkeletonLine height={'20px'} width={'20px'} margin={'0 0 0 0'} customStyles={{ borderRadius: '3px' }} />
                <SkeletonLine height={'20px'} width={'100%'} margin={'0 0 0 10px'} customStyles={{ borderRadius: '3px' }} />
            </div>
            <div className="mt-10">
                <Card customShadow border>
                    <SkeletonLine height={'25px'} width={'45%'} margin={'0 0 10px 0'} />
                    <div className={styles.items_list}>
                        <div className={`${styles.items_col} ${styles.flex_start}`} style={{ width: '100%' }}>
                            <SkeletonLine height={'10px'} width={'70%'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'10px 0 0 0'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'10px 0 0 0'} />
                        </div>
                    </div>
                    <SkeletonLine height={'140px'} width={'100%'} margin={'10px 0 0 0'} />
                </Card>
            </div>
        </div>
    );
};

export const SkeletonAccountPage = () => {
    return (
        <div>
            <div className={styles.items_list}>
                <SkeletonLine height={'40px'} width={'100%'} margin={'0 5px 10px 0'} />
            </div>
            <div>
                <Card customShadow border>
                    <div className={styles.items_list}>
                        <SkeletonLine height={'40px'} width={'50px'} margin={'0 10px 10px 0'} customStyles={{ borderRadius: '100%' }} />
                        <div className={`${styles.items_col} ${styles.flex_start}`} style={{ width: '100%' }}>
                            <SkeletonLine height={'10px'} width={'70%'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'10px 0 0 0'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'10px 0 0 0'} />
                        </div>
                    </div>
                    <div className={styles.items_list}>
                        <SkeletonLine height={'30px'} width={'50%'} margin={'10px 5px 0 0'} />
                        <SkeletonLine height={'30px'} width={'50%'} margin={'10px 0 0 5px'} />
                    </div>
                </Card>
                <div className="mt-10">
                    <Card customShadow border>
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                        <SkeletonLine height={'30px'} width={'100%'} margin={'0 0 10px 0'} />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export const SkeletonNotifyPage = () => {
    return (
        <div className={`container`}>
            <div className={styles.items_list}>
                <SkeletonLine height={'20px'} width={'70px'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'20px'} margin={'0 0 10px 0'} />
            </div>
            <Card customShadow border>
                {[...Array(10)].map((_, i) => (
                    <SkeletonProductList key={i} />
                ))}
            </Card>
        </div>
    );
};

export const SkeletonProductList = ({ rowClass, count }: any) => {
    return count ? (
        <div className={`${rowClass ? 'cat_row' : 'row'} ${styles.product_grid}`}>
            {[...Array(count)].map((_, i) => (
                <div className="col-xs-12" key={i}>
                    <div className={styles.product_list}>
                        <div>
                            <SkeletonAvatar height={'70px'} width={'70px'} radius={'10px'} />
                        </div>
                        <div className={styles.content}>
                            <SkeletonLine height={'10px'} width={'20%'} margin={'0 5px 10px 0'} />
                            <SkeletonLine height={'10px'} width={'50%'} margin={'0 5px 10px 0'} />
                            <SkeletonLine height={'10px'} width={'100%'} margin={'0 5px 10px 0'} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div className={styles.product_list}>
            <div>
                <SkeletonAvatar height={'70px'} width={'70px'} radius={'10px'} />
            </div>
            <div className={styles.content}>
                <SkeletonLine height={'10px'} width={'20%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'10px'} width={'50%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'10px'} width={'100%'} margin={'0 5px 10px 0'} />
            </div>
        </div>
    );
};

export const SkeletonOrderList = () => {
    return (
        <div className={`container`}>
            {[...Array(4)].map((_, i) => (
                <div className={`${orderStyle.order_details_wrp} ${orderStyle.order_list_wrp} mb-10`} key={i}>
                    <div className={`${orderStyle.order_list}`}>
                        <div className={orderStyle.header}>
                            <SkeletonLine height={'10px'} width={'70%'} margin={'0 10px 0 0'} />
                            <SkeletonLine height={'10px'} width={'100%'} margin={'0 0 0 0'} />
                        </div>
                        <div className={orderStyle.content}>
                            <div>
                                <SkeletonLine height={'10px'} width={'20%'} margin={'0 10px 0 0'} />
                                <SkeletonLine height={'10px'} width={'70%'} margin={'0 0 0 0'} />
                            </div>
                            <div>
                                <SkeletonLine height={'10px'} width={'25%'} margin={'0 10px 0 0'} />
                                <SkeletonLine height={'10px'} width={'50%'} margin={'0 0 0 0'} />
                            </div>
                            <div>
                                <SkeletonLine height={'10px'} width={'35%'} margin={'0 10px 0 0'} />
                                <SkeletonLine height={'10px'} width={'40%'} margin={'0 0 0 0'} />
                            </div>
                        </div>
                        <div className="p-10">
                            <SkeletonLine height={'35px'} width={'100%'} margin={'0 0 0 0'} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const SkeletonOrderDetails = () => {
    return (
        <div className={`container`}>
            <div className={orderStyle.order_details_wrp}>
                {[...Array(5)].map((_, i) => (
                    <div className={orderStyle.order_details} key={i}>
                        <div>
                            <SkeletonLine height={'10px'} width={'70%'} margin={'0 5px 10px 0'} />
                            <SkeletonLine height={'10px'} width={'100%'} margin={'0 5px 10px 0'} />
                        </div>
                        <div className={orderStyle.lines}>
                            <SkeletonAvatar height={'20px'} width={'20px'} radius={'100%'} />
                            <SkeletonLine height={'50px'} width={'3px'} margin={'5px 0 0 0'} />
                        </div>
                        <div>
                            <SkeletonLine height={'10px'} width={'50%'} margin={'0 5px 10px 0'} />
                            <SkeletonLine height={'10px'} width={'100%'} margin={'0 5px 10px 0'} />
                        </div>
                    </div>
                ))}
                <div className={orderStyle.flex_center}>
                    <SkeletonLine height={'10px'} width={'50%'} margin={'0 5px 10px 0'} />
                    <SkeletonLine height={'10px'} width={'90%'} margin={'0 5px 10px 0'} />
                </div>
            </div>

            <div className={`mt-10`}>
                <Card customShadow border>
                    {[...Array(5)].map((_, i) => (
                        <SkeletonProductList key={i} />
                    ))}
                </Card>
            </div>
        </div>
    );
};

export const SkeletonProductCard = ({ rowClass, count = 5 }: any) => {
    return (
        <div className={`${rowClass ? 'cat_row' : 'row'} ${styles.product_grid}`}>
            {[...Array(count)].map((_, i) => (
                <div className="col-xs-6 col-sm-3" key={i}>
                    <div className={`${styles.border}`}>
                        <div className={styles.img_wrap}>
                            <div className={styles.top_off}>
                                <SkeletonLine height={'30px'} width={'30px'} customStyles={{ borderRadius: '6px' }} />
                            </div>
                            <Img src={undefined} />
                            <div className={styles.img_skeleton}>
                                <SkeletonLine height={'100%'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
                            </div>
                        </div>
                        <div className={styles.content}>
                            <SkeletonLine height={'10px'} width={'70%'} customStyles={{ borderRadius: '4px' }} margin={'10px 0 0 0'} />
                            <SkeletonLine height={'4px'} width={'50%'} customStyles={{ borderRadius: '2px' }} margin={'5px 0 0 0'} />
                            <br />
                            <br />
                            <div className={styles?.bottom}>
                                <div className={styles.btm_cnt}>
                                    <SkeletonLine
                                        height={'10px'}
                                        width={'100%'}
                                        customStyles={{ borderRadius: '4px' }}
                                        margin={'10px 0 0 0'}
                                    />
                                    <SkeletonLine
                                        height={'5px'}
                                        width={'100%'}
                                        customStyles={{ borderRadius: '4px' }}
                                        margin={'10px 0 0 0'}
                                    />
                                </div>
                                <SkeletonLine height={'40px'} width={'40%'} customStyles={{ borderRadius: '6px' }} margin={'5px 0 0 0'} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const SkeletonProductCardHome = ({ rowClass, count = 5 }: any) => {
    return (
        <div className={`${'container'} ${styles.product_grid}`}>
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <SkeletonLine height={'150px'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <DraggableSlider>
                {[...Array(count)].map((_, i) => (
                    <div className={styles.max_width_product} key={i}>
                        <div className={`${styles.border}`}>
                            <div className={styles.img_wrap}>
                                <Img src={undefined} />
                                <div className={styles.img_skeleton}>
                                    <SkeletonLine height={'100%'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                            </div>
                            {/* <div className={styles.content}>
                                <SkeletonLine height={'10px'} width={'70%'} customStyles={{ borderRadius: '4px' }} margin={'10px 0 0 0'} />
                                <SkeletonLine height={'4px'} width={'50%'} customStyles={{ borderRadius: '2px' }} margin={'5px 0 0 0'} />
                                <br />
                                <br />
                                <div className={styles?.bottom}>
                                    <div className={styles.btm_cnt}>
                                        <SkeletonLine
                                            height={'10px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                        <SkeletonLine
                                            height={'5px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                    </div>
                                    <SkeletonLine
                                        height={'30px'}
                                        width={'40%'}
                                        customStyles={{ borderRadius: '6px' }}
                                        margin={'5px 0 0 0'}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                ))}
            </DraggableSlider>
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <SkeletonLine height={'40px'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <DraggableSlider>
                {[...Array(count)].map((_, i) => (
                    <div className={styles.max_width_product} key={i}>
                        <div className={`${styles.border}`}>
                            <div className={styles.img_wrap}>
                                <div className={styles.top_off}>
                                    <SkeletonLine height={'30px'} width={'30px'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                                <Img src={undefined} />
                                <div className={styles.img_skeleton}>
                                    <SkeletonLine height={'100%'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                            </div>
                            <div className={styles.content}>
                                <SkeletonLine height={'10px'} width={'70%'} customStyles={{ borderRadius: '4px' }} margin={'10px 0 0 0'} />
                                <SkeletonLine height={'4px'} width={'50%'} customStyles={{ borderRadius: '2px' }} margin={'5px 0 0 0'} />
                                <br />
                                <br />
                                <div className={styles?.bottom}>
                                    <div className={styles.btm_cnt}>
                                        <SkeletonLine
                                            height={'10px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                        <SkeletonLine
                                            height={'5px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                    </div>
                                    <SkeletonLine
                                        height={'30px'}
                                        width={'40%'}
                                        customStyles={{ borderRadius: '6px' }}
                                        margin={'5px 0 0 0'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </DraggableSlider>
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <DraggableSlider>
                {[...Array(count)].map((_, i) => (
                    <div className={styles.max_width_product} key={i}>
                        <div className={`${styles.border}`}>
                            <div className={styles.img_wrap}>
                                <div className={styles.top_off}>
                                    <SkeletonLine height={'30px'} width={'30px'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                                <Img src={undefined} />
                                <div className={styles.img_skeleton}>
                                    <SkeletonLine height={'100%'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                            </div>
                            <div className={styles.content}>
                                <SkeletonLine height={'10px'} width={'70%'} customStyles={{ borderRadius: '4px' }} margin={'10px 0 0 0'} />
                                <SkeletonLine height={'4px'} width={'50%'} customStyles={{ borderRadius: '2px' }} margin={'5px 0 0 0'} />
                                <br />
                                <br />
                                <div className={styles?.bottom}>
                                    <div className={styles.btm_cnt}>
                                        <SkeletonLine
                                            height={'10px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                        <SkeletonLine
                                            height={'5px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                    </div>
                                    <SkeletonLine
                                        height={'30px'}
                                        width={'40%'}
                                        customStyles={{ borderRadius: '6px' }}
                                        margin={'5px 0 0 0'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </DraggableSlider>
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <DraggableSlider>
                {[...Array(count)].map((_, i) => (
                    <div className={styles.max_width_product} key={i}>
                        <div className={`${styles.border}`}>
                            <div className={styles.img_wrap}>
                                <div className={styles.top_off}>
                                    <SkeletonLine height={'30px'} width={'30px'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                                <Img src={undefined} />
                                <div className={styles.img_skeleton}>
                                    <SkeletonLine height={'100%'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                            </div>
                            <div className={styles.content}>
                                <SkeletonLine height={'10px'} width={'70%'} customStyles={{ borderRadius: '4px' }} margin={'10px 0 0 0'} />
                                <SkeletonLine height={'4px'} width={'50%'} customStyles={{ borderRadius: '2px' }} margin={'5px 0 0 0'} />
                                <br />
                                <br />
                                <div className={styles?.bottom}>
                                    <div className={styles.btm_cnt}>
                                        <SkeletonLine
                                            height={'10px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                        <SkeletonLine
                                            height={'5px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                    </div>
                                    <SkeletonLine
                                        height={'30px'}
                                        width={'40%'}
                                        customStyles={{ borderRadius: '6px' }}
                                        margin={'5px 0 0 0'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </DraggableSlider>
            <div style={{ marginTop: '10px', lineHeight: '0' }}>&nbsp;</div>
            <DraggableSlider>
                {[...Array(count)].map((_, i) => (
                    <div className={styles.max_width_product} key={i}>
                        <div className={`${styles.border}`}>
                            <div className={styles.img_wrap}>
                                <div className={styles.top_off}>
                                    <SkeletonLine height={'30px'} width={'30px'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                                <Img src={undefined} />
                                <div className={styles.img_skeleton}>
                                    <SkeletonLine height={'100%'} width={'100%'} customStyles={{ borderRadius: '6px' }} />
                                </div>
                            </div>
                            <div className={styles.content}>
                                <SkeletonLine height={'10px'} width={'70%'} customStyles={{ borderRadius: '4px' }} margin={'10px 0 0 0'} />
                                <SkeletonLine height={'4px'} width={'50%'} customStyles={{ borderRadius: '2px' }} margin={'5px 0 0 0'} />
                                <br />
                                <br />
                                <div className={styles?.bottom}>
                                    <div className={styles.btm_cnt}>
                                        <SkeletonLine
                                            height={'10px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                        <SkeletonLine
                                            height={'5px'}
                                            width={'100%'}
                                            customStyles={{ borderRadius: '4px' }}
                                            margin={'10px 0 0 0'}
                                        />
                                    </div>
                                    <SkeletonLine
                                        height={'30px'}
                                        width={'40%'}
                                        customStyles={{ borderRadius: '6px' }}
                                        margin={'5px 0 0 0'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </DraggableSlider>
            <div style={{ marginBottom: '10px', lineHeight: '0' }}>&nbsp;</div>
        </div>
    );
};

export const SkeletonCatSidebar = ({ rowClass, count = 9 }: any) => {
    return (
        <>
            {[...Array(count)].map((_, i) => (
                <div className={`${styles.items_center_col} pb-10`} key={i}>
                    <SkeletonLine height={'60px'} width={'60px'} customStyles={{ borderRadius: '100%' }} margin={'5px 0 0 0'} />
                    <SkeletonLine height={'10px'} width={'100%'} customStyles={{ borderRadius: '6px' }} margin={'5px 0 0 0'} />
                </div>
            ))}
        </>
    );
};

export const SkeletonReviewList = ({ count = 5 }: any) => {
    return count ? (
        <>
            {[...Array(count)].map((_, i) => (
                <div className={styles.review_list} key={i}>
                    <div className={styles.top}>
                        <SkeletonLine height={'20px'} width={'20%'} margin={'0 5px 20px 0'} />
                        <SkeletonLine height={'20px'} width={'20%'} margin={'0 5px 20px 0'} />
                    </div>
                    <div className={styles.content}>
                        <SkeletonLine height={'10px'} width={'20%'} margin={'0 5px 10px 0'} />
                        <SkeletonLine height={'10px'} width={'50%'} margin={'0 5px 10px 0'} />
                        <SkeletonLine height={'10px'} width={'100%'} margin={'0 5px 10px 0'} />
                    </div>
                    <div className={styles.avatar}>
                        <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                        <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                        <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                        <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                        <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                        <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                    </div>
                </div>
            ))}
        </>
    ) : (
        <div className={styles.review_list}>
            <div className={styles.top}>
                <SkeletonLine height={'20px'} width={'20%'} margin={'0 5px 20px 0'} />
                <SkeletonLine height={'20px'} width={'20%'} margin={'0 5px 20px 0'} />
            </div>
            <div className={styles.content}>
                <SkeletonLine height={'10px'} width={'20%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'10px'} width={'50%'} margin={'0 5px 10px 0'} />
                <SkeletonLine height={'10px'} width={'100%'} margin={'0 5px 10px 0'} />
            </div>
            <div className={styles.avatar}>
                <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
                <SkeletonAvatar height={'60px'} width={'60px'} radius={'10px'} />
            </div>
        </div>
    );
};
